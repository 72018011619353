
import { defineComponent, onMounted, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import Bus from "@/common/ts/Bus";
import { useStore } from "vuex";

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const updateEmailButton = ref<HTMLButtonElement | null>(null);
    const updatePasswordButton = ref<HTMLButtonElement | null>(null);
    const formEmail = ref({});
    const formPassword = ref({});
    const emailFormDisplay = ref(false);
    const passwordFormDisplay = ref(false);
    const store = useStore();
    const user = computed(() => {
      return store.getters.currentUser;
    });

    const changeEmail = Yup.object().shape({
      emailaddress: Yup.string()
        .required("Email是必填项")
        .email("Email格式不符"),
      email_user_password: Yup.string().required("登录密码是必填项"),
    });

    const changePassword = Yup.object().shape({
      current_password: Yup.string().required("当前密码是必填项"),
      new_password: Yup.string()
        .min(6, "新的密码长度至少6位")
        .required("新的密码是必填项")
        .label("Password"),
      confirm_password: Yup.string()
        .min(6, "确认密码长度至少6位")
        .required("确认密码是必填项")
        .oneOf([Yup.ref("new_password"), null], "两次输入的新密码不一致"),
    });

    const updateEmail = () => {
      if (updateEmailButton.value) {
        //eslint-disable-next-line
        updateEmailButton.value!.disabled = true;
        //Activate indicator
        updateEmailButton.value.setAttribute("data-kt-indicator", "on");

        ApiService.post(
          "MemberProfile/EmailEdit",
          formEmail.value as AxiosRequestConfig
        )
          .then(({ data }) => {
            if (data.success == true) {
              emailFormDisplay.value = false;
              Bus.$emit("getUserDetail");
              Toast.success(data.message);
            } else {
              Toast.warning(data.message);
            }
          })
          .catch((error) => {
            Toast.error(error.message);
          })
          .finally(() => {
            //eslint-disable-next-line
            updateEmailButton.value!.disabled = false;
            //Deactivate indicator
            updateEmailButton.value?.removeAttribute("data-kt-indicator");
          });
      }
    };

    const updatePassword = () => {
      if (updatePasswordButton.value) {
        //eslint-disable-next-line
        updatePasswordButton.value!.disabled = true;
        //Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");

        ApiService.post(
          "MemberProfile/PasswordEdit",
          formPassword.value as AxiosRequestConfig
        )
          .then(({ data }) => {
            if (data.success == true) {
              passwordFormDisplay.value = false;
              //Bus.$emit("getUserDetail");
              Toast.success(data.message);
            } else {
              Toast.warning(data.message);
            }
          })
          .catch((error) => {
            Toast.error(error.message);
          })
          .finally(() => {
            //eslint-disable-next-line
            updatePasswordButton.value!.disabled = false;
            //Deactivate indicator
            updatePasswordButton.value?.removeAttribute("data-kt-indicator");
          });
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("账号与安全", ["账户"]);
      (formEmail.value as any).email = store.getters.currentUser.email;
    });

    return {
      emailFormDisplay,
      passwordFormDisplay,
      changeEmail,
      changePassword,
      updateEmailButton,
      updatePasswordButton,
      updateEmail,
      updatePassword,
      formEmail,
      formPassword,
      user
    };
  },
});
